
import { defineComponent, onMounted, ref, watch } from "vue";
import { useI18n } from "vue-i18n";
import { useRoute } from "vue-router";
import { ScrollComponent } from "@/assets/ts/components/_ScrollComponent";
import { MenuComponent } from "@/assets/ts/components/MenuComponent";
import { asideMenuIcons } from "@/core/helpers/config";
import MainMenuConfig from "@/core/config/MainMenuConfig";
import AuthService from "@/services/AuthService";
import distribuidorConfig from "@/core/config/DistribuidorConfig";
import store from "@/store/index";
import { useStore } from "vuex";

export default defineComponent({
  name: "kt-menu",
  components: {},
  setup() {
    const { t, te } = useI18n();
    const route = useRoute();
    const scrollElRef = ref<null | HTMLElement>(null);
    const userInfo = AuthService.getUsuarioAuth();
    const codNivel = userInfo.codNivel;
    let distribuidor = store.getters.layoutConfig("distribuidor.distribuidor");
    const storeVueX = useStore();
    const menu = ref<any>(MainMenuConfig)

    storeVueX.watch(() => store.getters.modeloAgendaName, (scheduleModel) => {
      if (scheduleModel) {
        menuAgendaVirtual(scheduleModel)
      }
    }, { deep: true });

    onMounted(() => {
      ScrollComponent.reinitialization();
      MenuComponent.reinitialization();
      if (scrollElRef.value) {
        scrollElRef.value.scrollTop = 0;
      }
    });

    const menuAgendaVirtual = (scheduleModel: string) => {
      let newMenuConfig;
      newMenuConfig = JSON.parse(JSON.stringify(MainMenuConfig));

      if (scheduleModel === 'QuadroDigitalPorOrdemDeServico') {
        console.log('scheduleModel if', scheduleModel)
        const menuAgendaVirtual = newMenuConfig[0].pages.find(
          (menuItem) =>
            "sectionTitle" in menuItem && menuItem.sectionTitle === "Agenda Virtual"
        );

        //retira o modulo de recepção e agendamento do menu
        if (menuAgendaVirtual && "sub" in menuAgendaVirtual) {
          menuAgendaVirtual.sub.splice(0, 2);
        }

        menu.value = newMenuConfig;
      } else {
        menu.value = MainMenuConfig
      }

    }

    const translate = (text) => {
      if (te(text)) {
        return t(text);
      } else {
        return text;
      }
    };

    const hasActiveChildren = (match) => {
      return route.path.indexOf(match) !== -1;
    };

    return {
      hasActiveChildren,
      MainMenuConfig,
      asideMenuIcons,
      translate,
      codNivel,
      distribuidor,
      menu
    };
  },
});
